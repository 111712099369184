@charset "utf-8";

@import "utils";

// Our variables
$base-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

// Colors
$text-color:            #566b78;
$text-important-color:  #26343c;
$text-color-light:      lighten($text-color, 40%);
$text-color-dark:       darken($text-color, 25%);
$background-color:      #fdfdfd;
// Pallet colors
$pallet-color-1:         #f98866; // (petal)
$pallet-color-2:         #ff420e; // (poppy)
$pallet-color-3:         #80bd9e; // (stem)
$pallet-color-4:         #89da59; // (spring-green)
// Base and Alternative colors
$base-color:             $pallet-color-3;
$alt-color:              $pallet-color-2;
// Shades and tints
$percentage: 20%;
$base-color-tint: tint($base-color, $percentage);
$base-color-shade: shade($base-color, $percentage);
$alt-color-tint: tint($alt-color, $percentage);
$alt-color-shade: shade($alt-color, $percentage);

// Bootstrap 3 screen sizes
$screen-xs-max:     767px;  /* Extra small devices (phones, less than 768px) */
$screen-sm-min:     768px;  /* Small devices (tablets, 768px and up) */
$screen-md-min:     992px;  /* Medium devices (desktops, 992px and up) */
$screen-lg-min:     1200px; /* Large devices (large desktops, 1200px and up) */

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "navbar",
        "syntax-highlighting",
        "layout",
        "style"
;
