body {
    line-height: $base-line-height;
    color: $text-color;
    font-family: $base-font-family;
}
h2 {
    margin-top: 1em;
    padding-top: 1em;
}
h1,
h2,
strong {
    color: $text-important-color;
}
a {
    color: $base-color;
    &:active, &:focus, &:hover {
        color: $alt-color;
    }
}
// Code
code {
    padding: 2px 4px;
    vertical-align: text-bottom;
}
code,
pre {
    background: #f5f7f9;
}
pre {
    padding: 1em;
    border-left: 2px solid $alt-color-tint;
}
.bootstrap-content {
    //padding: 76px 0 0 0;
    //padding: 66px 0 0 0;
    padding: 0;
    flex: 1;
  }
// Header
header.bannerheader {
    position: relative;
    background-color: #263d36;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 1.2;
    padding: 10vw 0;
    text-align: center;
    background-image: url("/assets/img/header-xs.jpg");
    @include media-query($screen-sm-min) {
        background-image: url("/assets/img/header-sm.jpg");
    }
    @include media-query($screen-md-min) {
        background-image: url("/assets/img/header-md.jpg");
    }
    @include media-query($screen-lg-min) {
        background-image: url("/assets/img/header-lg.jpg");
    }
    img {
        background-color: $base-color;
        display: inline-block;
        height: 120px;
        vertical-align: top;
        width: 120px;
        border-radius: 50%;
        transition: background-color 0.6s ease;
        &:hover {
            background-color: $alt-color;
        }
    }
    h1 {
        color: white;
        font-size: 2.5em;
        font-weight: 300;
    }
    a {
        border: 1px solid $base-color;
        border-radius: 290486px;
        color: white;
        font-size: 0.6em;
        letter-spacing: 0.2em;
        padding: 1em 2em;
        text-transform: uppercase;
        text-decoration: none;
        transition: none 200ms ease-out;
        transition-property: color, background;
        &:hover {
            background: $base-color;
            color: white;
        }
    }
    p.lead {
        color: white;
        font-weight: 100;
    }
}
// Bootstrap thumbnail
a.thumbnail img {
    min-width: 100%;
}
a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
    border-color: $base-color-shade;
}
// Bootstrap navbar-brand
.navbar-brand-image img {
    background-color: $base-color;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color 0.6s ease;
    &:hover {
        background-color: $alt-color;
    }
}
// Navbar-toggle hamburger menu animation
.navbar-toggle {
    padding: 15px 10px;
}
.navbar-toggle .hamb-menu {
    border-radius: 2px;
    display: block;
    height: 4px;
    width: 30px;
    background: none;
}
.navbar-toggle .hamb-menu::after,
.navbar-toggle .hamb-menu::before {
    position: absolute;
    border-radius: 2px;
    display: block;
    width: 30px;
    height: 4px;
    background-color: $base-color;
    content: "";
}
// not collapsed state, i.e. menu open
.navbar-toggle .hamb-menu::before {
    transform: rotate(45deg);
    top: 15px;
}
.navbar-toggle .hamb-menu::after {
    transform: rotate(-45deg);
    bottom: 15px;
}
.navbar-toggle .hamb-menu::after,
.navbar-toggle .hamb-menu::before {
    background-color: $alt-color;
}
// collapsed state, i.e. menu closed
.navbar-toggle.collapsed .hamb-menu,
.navbar-toggle.collapsed .hamb-menu::after,
.navbar-toggle.collapsed .hamb-menu::before {
    background-color: $base-color;
}
.navbar-toggle.collapsed .hamb-menu::before {
    top: 3px;
    transform: rotate(0);
}
.navbar-toggle.collapsed .hamb-menu::after {
    bottom: 3px;
    transform: rotate(0);
}
.navbar-toggle .hamb-menu,
.navbar-toggle .hamb-menu::after,
.navbar-toggle .hamb-menu::before {
    transition: bottom 0.3s 0s, top 0.3s 0s , background 0.3s 0.3s, transform 0.3s 0.3s;
}
.navbar-toggle.collapsed .hamb-menu,
.navbar-toggle.collapsed .hamb-menu::after,
.navbar-toggle.collapsed .hamb-menu::before {
    transition: transform 0.3s 0s, background 0.3s 0s, bottom 0.3s 0.3s, top 0.3s 0.3s;
}
