/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

/// media-query mixin for minimum width
/// @access public
/// @param {Pixel} $screen-device-min - the device min width
@mixin media-query($screen-device-min) {
    @media (min-width: $screen-device-min) {
        @content;
    }
}
