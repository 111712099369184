/**
 * Site
 */
.site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.site-content {
    flex: 1;
    padding-bottom: 20px;
}
.site-section {
    padding: 60px 0;
}
/**
 * Navbar
 */
.navbar-brand-image {
    padding: 10px;
}
nav.navbar {
    border-bottom: 2px solid $base-color-tint;
    // Animation
    transition: background-color 0.6s ease;
}
nav.transp {
    background-color: transparent;
    border: 0;
}
.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    border: 0;
    background-color: transparent;
}
/**
 * Footer
 */
.footer-title {
    color: $base-color-shade;
}
.footer {
    background-color: $bgDefault;
    border-top: 2px solid $base-color-tint;
    padding: 30px 30px 0;
}
.footer address {
    display: block;
    font-style: italic;
}
/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}
.page-heading {
    font-size: 20px;
}
.post-list {
    margin-left: 0;
    list-style: none;
    > li {
        margin-bottom: $spacing-unit;
    }
}
.post-meta {
    font-size: $small-font-size;
    color: $text-color-light;
}
.post-link {
    display: block;
    font-size: 24px;
}
/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}
.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
    @include media-query($screen-md-min) {
        font-size: 36px;
    }
}
.post-content {
    margin-bottom: $spacing-unit;
    h2 {
        font-size: 32px;
        @include media-query($screen-md-min) {
            font-size: 28px;
        }
    }
    h3 {
        font-size: 26px;
        @include media-query($screen-md-min) {
            font-size: 22px;
        }
    }
    h4 {
        font-size: 20px;
        @include media-query($screen-md-min) {
            font-size: 18px;
        }
    }
}
/**
 * Projects Modal
 */
.modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    .modal-content {
        padding: 100px 0;
        min-width: 100%;
        min-height: 100%;
        border-radius: 0;
        border: 0;
        .modal-body {
            padding: 15px;
        }
        .close {
            z-index: 1042;
            position: fixed;
            top: 0;
            right: 0;
            padding: 1em;
        }
        .btn-default {
            border: 0;
        }
        .m-y-2 {
            border-top: 5px solid $base-color-tint;
        }
    }
}
